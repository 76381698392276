<template>
    <v-container class="pa-0 pa-md-3" fluid>
        <v-row no-gutters>
            <v-col v-if="$canView('suppliers')" cols="12" sm="6" lg="4" key="1">
                <v-card flat class="ma-0 ma-sm-1 ma-md-2">
                    <BT-List
                        navigation="suppliers"
                        :flat="false">
                        <template #items="{ items }">
                            <v-subheader>
                                Suppliers ({{ items.length }})
                                <v-spacer />
                                <BT-Btn rightIcon="mdi-magnify" small inline label="Find" :to="{ name: 'public-suppliers' }" />
                                <BT-Btn v-if="$BlitzIt.auth.session.subscriptionCode != 'CUSTFREE'" rightIcon="mdi-plus" small inline label="New Supplier" :to="{ name: 'supplier', params: { id: 'new' } }" />
                            </v-subheader>
                            <v-card class="overflow-y-auto" flat :style="mobile ? 'height: calc(35vh - 96px)' : 'height: calc(50vh - 96px)'">
                                <v-card v-for="(item, ind) in items" :key="ind" class="ma-2 pa-1" rounded="lg" @click="currentSupplier = currentSupplier === item ? null : item">
                                    <v-slide-y-transition group hide-on-leave>
                                        <div class="d-flex align-center mx-2" key="1">
                                            <BT-Avatar v-model="item.sellerID" />
                                            <div class="text-subtitle-1 ml-4">{{ item.seller.companyName }}</div>
                                        </div>
                                        <v-row v-if="currentSupplier === item" class="mt-2" no-gutters key="2">
                                            <v-col>
                                                <BT-Btn
                                                    block
                                                    label="Open"
                                                    leftIcon="mdi-open-in-new"
                                                    text
                                                    :to="{ name: 'public-supplier', params: { id: item.sellerID } }" />
                                            </v-col>
                                            <v-col>
                                                <BT-Btn
                                                    block
                                                    label="New Order"
                                                    leftIcon="mdi-file-document-plus"
                                                    text
                                                    :to="{ name: 'purchase-order', params: { id: 'new' }, query: { agreementID: item.id } }" />
                                            </v-col>
                                        </v-row>
                                    </v-slide-y-transition>
                                </v-card>
                            </v-card>
                        </template>
                    </BT-List>
                </v-card>
            </v-col>
            <v-col v-if="$canView('purchase-orders')" cols="12" sm="6" lg="4">
                <BT-Blade-Items
                    hideActions
                    hideBladeHeader
                    hideHeader
                    :itemsPerPage="5"
                    navigation="purchase-orders"
                    :onPullSuccessAsync="pullOrders"
                    pageVariant="transparent"
                    :refreshToggle="orderRefreshToggle"
                    @select="navToOrder"
                    showList
                    :tile="false">
                    <template v-slot:body="{ items }">
                        <v-card flat class="ma-0 ma-sm-1 ma-md-2">
                            <v-subheader>Existing Orders
                                <v-spacer />
                                <BT-Btn rightIcon="mdi-plus" small inline label="New Order" :to="{ name: 'purchase-order', params: { id: 'new' } }" />
                                <BT-Btn icon="mdi-repeat" isButton small :to="{ name: 'standing-purchase-orders' }" />
                            </v-subheader>
                            <v-card v-if="!isLengthyArray(items)" class="pb-1 " :to="{ name: 'purchase-order', params: { id: 'new' } }" flat>
                                <v-card class="ma-2 pa-1" rounded="lg" :ripple="false">
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon large>mdi-file-document-plus</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>Create New Order</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-card>
                            <!--  :height="$vuetify.breakpoint.mobile ? '45vh' : '40vh'" -->
                            <v-card v-else class="overflow-y-auto" flat :style="mobile ? 'height: calc(65vh - 104px)' : 'height: calc(50vh - 96px)'">
                                <v-card v-for="(item, fIndex) in items" :key="fIndex"
                                    class="ma-2 pa-1"
                                    @click="currentOrder = currentOrder === item ? null : item"
                                    :ripple="false"
                                    rounded="lg">
                                    <v-slide-y-transition group hide-on-leave>
                                        <v-list-item dense key="1">
                                            <BT-List-Item-Avatar v-model="item.sellerID" />
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span v-if="item.itemType == 'invoice'">INV# {{ item.invoiceNumber }}</span>
                                                    <span v-else>CO# {{ item.customerOrderNumber }}</span>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <span v-if="item.itemType == 'invoice'">Delivered on {{ item.issuedOn | toFormat('ccc dd LLL') }}</span>
                                                    <span v-else-if="item.dueDate == null">(No Due Date)</span>
                                                    <span v-else>Due on {{ item.dueDate | toFormat('ccc dd LLL') }}</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-list-item-action-text>
                                                    <span v-if="item.itemType == 'invoice'">{{ item.amountTotal | toCurrency }}</span>
                                                    <span v-else>{{ item.amountTotal | toCurrency }}</span>
                                                </v-list-item-action-text>
                                                <span v-if="item.itemType == 'invoice' && item.paidOn == null" class="error--text">Unpaid</span>
                                                <span v-else-if="item.itemType == 'invoice'" class="success--text">Paid</span>
                                                <span v-else-if="item.itemType == 'delivery'">Delivery Scheduled</span>
                                                <span v-else-if="item.isInactive" class="error--text">Deleted</span>
                                                <span v-else-if="item.isDispatched == true" class="warning--text">Processed</span>
                                                <span v-else-if="item.isConfirmed == null" class="warning--text">Awaiting Response</span>
                                                <span v-else-if="item.isConfirmed == true" class="warning--text">Confirmed</span>
                                                <span v-else class="error--text">Rejected</span>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-row v-if="currentOrder === item" class="mt-2" no-gutters key="2">
                                            <v-col>
                                                <BT-Btn
                                                    block
                                                    leftIcon="mdi-open-in-new"
                                                    label="Open"
                                                    text
                                                    @click="navToOrder(item)" />
                                            </v-col>
                                            <v-col v-if="item.itemType == 'order' && item.isConfirmed !== true">
                                                <BT-Btn
                                                    block
                                                    buttonClass="error--text"
                                                    leftIcon="mdi-delete"
                                                    label="Delete"
                                                    text
                                                    @click="deleteOrder(item)" />
                                            </v-col>
                                        </v-row>
                                    </v-slide-y-transition>
                                </v-card>
                            </v-card>
                        </v-card>
                    </template>
                </BT-Blade-Items>
            </v-col>
            <v-col v-if="$canView('suppliers') && showSchedule && !mobile" cols="12" sm="6" lg="4">
                <BT-Blade-Item
                    @fetched="updateShowSchedule"
                    customURL="/get/Calendar"
                    frameVariant="transparent"
                    hideBladeHeader
                    hideHeader
                    ignoreID
                    navigation="suppliers"
                    :onPullSuccessAsync="pullCalendar"
                    pageVariant="transparent"
                    :params="{ dateFrom: $BlitzIt.auth.getToday(), dateTo: $BlitzIt.auth.getFuture(14) }"
                    :refreshToggle="calendarRefreshToggle"
                    :scroll="false"
                    showList
                    single
                    :tile="false">
                    <template v-slot="{ item }">
                        <v-card flat class="ma-0 ma-sm-1 ma-md-2">
                            <v-subheader>Upcoming Schedule</v-subheader>
                            <v-card class="overflow-y-auto" flat style="height: calc(50vh - 96px)">
                                <v-card v-for="(order, inde) in item.scheduleItems" :key="inde" class="ma-2 pa-1" @click="currentScheduleItem = currentScheduleItem === order ? null : order" :ripple="false" rounded="lg">
                                    <v-slide-y-transition group hide-on-leave>
                                        <v-row no-gutters key="1">
                                            <v-col cols="6" class="d-flex flex-column justify-center align-center">
                                                <div class="mb-2">
                                                    <BT-Avatar v-model="order.sellerID" size="40" />
                                                </div>
                                            </v-col>
                                            <v-col cols="6" class="d-flex flex-column justify-center align-center">
                                                <div v-if="order.purchaseOrder != null" class="text-caption">CO# {{ order.purchaseOrder.customerOrderNumber }}</div>
                                                <!-- <v-alert v-else-if="order.isCancelled" color="error" dense class="pa-1 pl-2 mr-2"> -->
                                                <div v-else-if="order.isCancelled" class="d-flex align-center justify-center error--text">
                                                    <div>(Cancelled)</div>
                                                </div>
                                                <!-- </v-alert> -->
                                                <div v-else-if="order.standingOrder != null" class="text-caption">
                                                    <v-icon left>mdi-repeat-variant</v-icon>{{ order.standingOrder.standingOrderName }}
                                                </div>
                                                <v-alert v-else-if="order.purchaseOrder != null && order.purchaseOrder.isInactive" color="error" dense class="pa-1 pl-2 mr-2">
                                                    <div class="d-flex align-center justify-center">
                                                        <div class="grow">Deleted By Supplier</div>
                                                        <div class="shrink">
                                                            <BT-Btn
                                                                @click="deletePurchaseOrder(order)"
                                                                inline 
                                                                label="OK"
                                                                outlined 
                                                                small
                                                                text />
                                                        </div>
                                                    </div>
                                                </v-alert>
                                                <div v-else-if="order.purchaseOrder == null && order.standingOrder == null" class="text-caption faded-text--text">
                                                    (No Order Placed)
                                                </div>
                                                <div v-if="order.orderBy != null">
                                                    <BT-Countdown v-model="order.orderBy" />
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mt-2 pb-2 text-caption faded-text--text" key="3">
                                            <v-icon small class="mx-2 faded-text--text">mdi-file-document-arrow-right</v-icon>
                                            <div v-if="order.orderBy != null">{{ order.orderBy | toFormat('ccc dd LLL (h a)') }}</div>
                                            <v-divider class="my-auto mx-4" style="border-top: 4px dotted;" />
                                            <div v-if="order.deliverBy != null">{{ order.deliverBy | toFormat('ccc dd LLL') }}</div>
                                            <v-icon small class="mx-2 faded-text--text">mdi-truck-delivery</v-icon>
                                        </v-row>
                                        <v-row v-if="currentScheduleItem === order" no-gutters key="2">
                                            <v-col>
                                                <BT-Btn
                                                    block
                                                    @click="selectStandingOrder(order)"
                                                    :disabled="order.orderSlotID == null"
                                                    leftIcon="mdi-repeat-variant"
                                                    label="Repeat"
                                                    text />
                                            </v-col>
                                            <v-col v-if="!order.isCancelled && (order.standingOrderID != null || order.purchaseOrderID != null)">
                                                <BT-Menu hideHeader bottom>
                                                    <template v-slot:activator="{ openDialog }">
                                                        <BT-Btn
                                                            block
                                                            @click="openDialog"
                                                            leftIcon="mdi-pencil"
                                                            label="Edit"
                                                            text />
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click="navToScheduledItem(order)">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Edit This Order</v-list-item-title>
                                                                <v-list-item-subtitle v-if="order.purchaseOrder != null && order.purchaseOrder.isConfirmed">*Contact supplier to change*</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item :to="{ name: 'standing-purchase-order', query: { id: order.standingOrderID }}" :disabled="order.standingOrderID == null">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Edit Standing Order</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </BT-Menu>
                                            </v-col>
                                            <v-col v-else-if="!order.isCancelled">
                                                <BT-Menu hideHeader left>
                                                    <template v-slot:activator="{ openDialog }">
                                                        <BT-Btn
                                                            block
                                                            @click="openDialog"
                                                            leftIcon="mdi-file-document-plus"
                                                            label="New Order"
                                                            text />
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click="navToScheduledItem(order)">
                                                            <v-list-item-title>New Order</v-list-item-title>
                                                            <v-list-item-action>
                                                                <v-icon small>mdi-note-plus</v-icon>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                        <v-list-item :to="{ name: 'purchase-order', params: { id: 'new', repeatLast: true, agreementID: order.supplyAgreementID, dueDate: order.deliverBy } }">
                                                            <v-list-item-title>Repeat Last Order</v-list-item-title>
                                                            <v-list-item-action>
                                                                <v-icon small>mdi-history</v-icon>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                        <v-list-item :to="{ name: 'standing-purchase-order', params: { id: 'new', agreementID: order.supplyAgreementID, orderSlotID: order.orderSlotID }}">
                                                            <v-list-item-title>New Standing Order</v-list-item-title>
                                                            <v-list-item-action>
                                                                <v-icon small>mdi-repeat</v-icon>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </v-list>
                                                </BT-Menu>
                                            </v-col>
                                            <v-col v-if="order.isCancelled">
                                                <BT-Btn
                                                    block
                                                    buttonClass="error--text"
                                                    @click="cancelPurchaseOrder(order, false)"
                                                    leftIcon="mdi-undo"
                                                    label="Delete"
                                                    text />
                                            </v-col>
                                            <v-col v-if="!order.isCancelled && (order.standingOrderID != null || order.purchaseOrderID != null)">
                                                <BT-Menu hideHeader left>
                                                    <template v-slot:activator="{ openDialog }">
                                                        <BT-Btn
                                                            block
                                                            buttonClass="error--text"
                                                            @click="openDialog"
                                                            leftIcon="mdi-close"
                                                            label="Delete"
                                                            text />
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click="cancelPurchaseOrder(order, true)">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Cancel This Order</v-list-item-title>
                                                                <v-list-item-subtitle v-if="order.purchaseOrder != null && order.purchaseOrder.isConfirmed">*Contact supplier to cancel*</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item @click="cancelStandingOrder(order)" :disabled="order.standingOrderID == null">
                                                            <v-list-item-title>Cancel Standing Order</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </BT-Menu>
                                            </v-col>
                                        </v-row>
                                    </v-slide-y-transition>
                                </v-card>
                            </v-card>
                        </v-card>
                    </template>
                </BT-Blade-Item>
            </v-col>
            <v-col v-if="$canView('standing-purchase-orders') && !mobile" cols="12" sm="6" lg="4">
                <BT-Blade-Items
                    actualHeight="50vh - 96px"
                    hideActions
                    hideBladeHeader
                    hideHeader
                    :itemsPerPage="5"
                    navigation="standing-purchase-orders"
                    pageVariant="transparent"
                    :refreshToggle="standingRefreshToggle"
                    showList
                    :tile="false">
                    <template v-slot:body="{ items }">
                        <v-card flat class="ma-0 ma-sm-1 ma-md-2">
                            <v-subheader>Standing Orders
                                <v-spacer />
                                <v-btn class="" small :to="{ name: 'standing-purchase-order', params: { id: 'new' } }">New</v-btn>
                            </v-subheader>
                            <v-card v-if="!isLengthyArray(items)" class="pb-1 " :to="{ name: 'standing-purchase-order', params: { id: 'new' } }" flat>
                                <v-card class="ma-2 pa-1" rounded="lg">
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon large>mdi-file-document-plus</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>Create New Standing Order</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-card>
                            <v-card v-else class="overflow-y-auto" flat style="height: calc(50vh)">
                                <v-card v-for="(item, fIndex) in items" :key="fIndex"
                                    class="ma-2 pa-1"
                                    @click="currentStandingOrder = currentStandingOrder === item ? null : item"
                                    rounded="lg">
                                    <v-slide-y-transition group hide-on-leave>
                                        <v-list-item dense key="1">
                                            <BT-List-Item-Avatar v-model="item.sellerID" />
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.standingOrderName }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-row v-if="currentStandingOrder === item" class="mt-2" no-gutters key="2">
                                            <v-col>
                                                <BT-Btn
                                                    block
                                                    leftIcon="mdi-open-in-new"
                                                    label="Open"
                                                    text
                                                    :to="{ name: 'standing-purchase-order', params: { id: item.id } }" />
                                            </v-col>
                                            <v-col>
                                                <BT-Btn
                                                    block
                                                    buttonClass="error--text"
                                                    leftIcon="mdi-delete"
                                                    label="Delete"
                                                    text
                                                    @click="deleteStandingOrder(item)" />
                                            </v-col>
                                        </v-row>
                                    </v-slide-y-transition>
                                </v-card>
                            </v-card>
                        </v-card>
                    </template>
                </BT-Blade-Items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Customer-Dashboard',
    components: {
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTCountdown: () => import('~components/BT-Countdown.vue')
    },
    data: function() {
        return {
            calendarRefreshToggle: false,
            currentOrder: null,
            currentScheduleItem: null,
            currentStandingOrder: null,
            currentSupplier: null,
            loadingMsg: null,
            orderRefreshToggle: false,
            standingRefreshToggle: false,
            showSchedule: !this.$vuetify.breakpoint.mobile,
        }
    },
    computed: {
        mobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        async cancelPurchaseOrder(schItem, cancel = false) {
            try {
                this.loadingMsg = 'Cancelling Purchase Order';

                if (cancel && schItem.purchaseOrderID != null) {
                    if (schItem.purchaseOrder.isConfirmed === true) {
                        await this.$alert('Please contact the supplier to cancel your order');
                        return;
                    }
                    await this.$BlitzIt.api.delete('purchase-orders', schItem.purchaseOrder);
                    schItem.purchaseOrder = null;
                    schItem.purchaseOrderID = null;
                }

                if (schItem.standingOrderID != null) {
                    
                    var data = {
                        standingOrderID: schItem.standingOrderID,
                        replacingDate: cancel ? null : schItem.orderBy,
                        dateTrigger: schItem.orderBy,
                    };
                    console.log(data);
                    await this.$BlitzIt.api.post('suppliers', data, null, '/post/Calendar');

                    schItem.isCancelled = cancel;
                }
                
                this.calendarRefreshToggle = !this.calendarRefreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async cancelStandingOrder(schItem) {
            try {
                this.loadingMsg = 'Cancelling Standing Order';
                await this.$BlitzIt.api.post('standing-purchase-orders', { orderSlotID: schItem.orderSlotID, standingOrderID: schItem.standingOrderID }, null, '/post/remove');
                this.calendarRefreshToggle = !this.calendarRefreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async deleteStandingOrder(order) {
            try {
                this.loadingMsg = 'Deleting Standing Order';
                await this.$BlitzIt.store.delete('standing-purchase-orders', order.id);
                this.standingRefreshToggle = !this.standingRefreshToggle
                this.orderRefreshToggle = !this.orderRefreshToggle;
                if (this.showSchedule) {
                    this.calendarRefreshToggle = !this.calendarRefreshToggle;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async deleteOrder(order) {
            try {
                this.loadingMsg = 'Deleting Purchase Order';
                await this.$BlitzIt.store.delete('purchase-orders', order.id)
                this.orderRefreshToggle = !this.orderRefreshToggle;
                if (this.showSchedule) {
                    this.calendarRefreshToggle = !this.calendarRefreshToggle;
                }
            }
            catch (err) {
                console.log(this.extractErrorDescription(err));
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async deletePurchaseOrder(schItem) {
            try {
                this.loadingMsg = 'Deleting Purchase Order';
                await this.$BlitzIt.api.delete('purchase-orders', schItem.purchaseOrder);
                this.calendarRefreshToggle = !this.calendarRefreshToggle;
            }
            catch (err) {
                console.log(this.extractErrorDescription(err));
            }
            finally {
                this.loadingMsg = null;
            }
        },
        navToOrder(item) {
            if (item.itemType == 'invoice') {
                this.$router.push({ name: 'supplier-invoice', params: { id: item.id }});
            }
            else if (item.itemType == 'order') {
                this.$router.push({ name: 'purchase-order', params: { 
                    id: item.id }});
            }
        },
        navToScheduledItem(item) {
            if (item.purchaseOrderID != null) {
                this.$router.push({ name: 'purchase-order', params: { 
                    id: item.purchaseOrderID }});
            }
            else if (item.standingOrderID != null) {
                this.$router.push({ name: 'purchase-order', params: {
                    dueDate: item.deliverBy,
                    id: 'new',
                    standingOrderID: item.standingOrderID,
                    agreementID: item.supplyAgreementID,
                } });
            }
            else {
                this.$router.push({ name: 'purchase-order', params: {
                    dueDate: item.deliverBy,
                    id: 'new',
                    agreementID: item.supplyAgreementID
                }});
            }
        },
        async pullCalendar(item) {
        console.log('pulling calendar')
            if (item != null) {
                item.scheduleItems = item.scheduleItems.filter(z => z.orderBy != null);
            }

            return item;
        },
        async pullOrders(list) {
            var r = list.map(x => Object.assign({}, { itemType: 'order', orderByDate: x.dueDate, ...x }));

            //get last two recent invoices
            var invoiceRes = await this.$BlitzIt.store.getAll('supplier-invoices', { sortBy: 'IssuedOn', takeAmount: 2 });
            
            if (this.isLengthyArray(invoiceRes)) {
                invoiceRes.forEach(inv => {
                    r.unshift(Object.assign({}, { itemType: 'invoice', orderByDate: inv.issuedOn, ...inv }));
                })
            }

            return r.orderByDate(x => x.orderByDate, false);
        },
        selectScheduleItem(item) {
            if (this.currentScheduleItem === item) {
                this.currentScheduleItem = null
            }
            else {
                this.currentScheduleItem = item
            }
        },
        async selectStandingOrder(schItem) {
            var sOrder = await this.$selectItem({
                navigation: 'standing-purchase-orders',
                height: 'auto',
                itemText: 'standingOrderName',
                label: 'Standing Orders',
                params: { supplierIDs: schItem.sellerID },
                showSearch: false
            });

            if (sOrder != null) {
                try {
                    this.loadingMsg = 'Updating Standing Order';
                    if (schItem.standingOrderID != null) {
                        //remove
                        var d = { orderSlotID: schItem.orderSlotID, standingOrderID: schItem.standingOrderID };
                        console.log('removing');
                        console.log(d);
                        await this.$BlitzIt.api.post('standing-purchase-orders', d, null, '/post/remove');
                    }

                    await this.$BlitzIt.api.post('standing-purchase-orders', { orderSlotID: schItem.orderSlotID, standingOrderID: sOrder.id }, null, '/post/add');
                    this.calendarRefreshToggle = !this.calendarRefreshToggle;
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        },
        updateShowSchedule(data) {
            if (data == null || !this.isLengthyArray(data.scheduleItems)) {
                this.showSchedule = false;
            }
        }
    }
}
</script>